// template-generated
import { ofetch } from 'ofetch';
import { fauxLogger as $logger } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt-umami/dist/runtime/logger";

/**
 * @typedef {import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt-umami/dist/types").FetchFn} FetchFn
 * 
 * @typedef {import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt-umami/dist/types").BuildPathUrlFn} BuildPathUrlFn
 * 
 * @typedef {import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt-umami/dist/types").UmPublicConfig} UmPublicConfig
 */

export const logger = $logger;

/**
 * @type UmPublicConfig
*/
export const config = {
  "trailingSlash": "any",
  "ignoreLocalhost": true,
  "autoTrack": true,
  "useDirective": false,
  "excludeQueryParams": false,
  "enabled": true,
  "domains": null,
  "website": "5202b398-149e-47fb-a634-f7adffe67eb8",
  "endpoint": "https://analytics.abcreche.com/api/send"
};

const { endpoint, website, enabled } = config;
let cache = '';

function handleError(err) {
  try {
    const cause = typeof err.data === 'string' ? err.data : err.data.data;
    if (cause && typeof cause === 'string')
      logger('collect', cause);
    else throw new Error('Unknown error');
  }
  catch {
    logger('collect', err);
  }
  return { ok: false };
}

function handleSuccess(response) {
  cache = typeof response === 'string' ? response : '';
  return { ok: true };
}

/**
 * @type BuildPathUrlFn
 */
export function buildPathUrl() {
  const { pathname, search } = new URL(window.location.href);

  const path = pathname;

  return path + search;
}

/**
 * @type FetchFn 
 * 
 * @variation direct
 */
export async function collect(load) {
  const { type, payload } = load;

  return ofetch(endpoint, {
    method: 'POST',
    headers: { ...(cache && { 'x-umami-cache': cache }) },
    body: { type, payload: { ...payload, website } },
  })
    .then(handleSuccess)
    .catch(handleError);
}
