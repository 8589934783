import { default as indexIDkhg8fgYxMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Activity/index.vue?macro=true";
import { default as dayodZGYibkMuMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/day.vue?macro=true";
import { default as index41WbAzsexkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/index.vue?macro=true";
import { default as week8RLB4HVz9YMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/week.vue?macro=true";
import { default as loginfky1OE6c5lMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/login.vue?macro=true";
import { default as logoutxtSRgNbZF4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/logout.vue?macro=true";
import { default as indexj0H54jjC15Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Availability/index.vue?macro=true";
import { default as activitiesP1xPEaHZsIMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/activities.vue?macro=true";
import { default as calendarZkmTVA09KPMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/calendar.vue?macro=true";
import { default as classroom40SNHbGiDhMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/classroom.vue?macro=true";
import { default as documentsBDgPDIH4uAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/documents.vue?macro=true";
import { default as financeaBHku2DmAmMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/finance.vue?macro=true";
import { default as history5sqhFdUxQwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/history.vue?macro=true";
import { default as indexsEdbbm1nt1Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/index.vue?macro=true";
import { default as medicalqCpTEWH5kfMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/medical.vue?macro=true";
import { default as parents5OpXyW4nDcMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/parents.vue?macro=true";
import { default as registrationHHy2OBQpOSMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/registration.vue?macro=true";
import { default as archivedME7uO4NxGOMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/archived.vue?macro=true";
import { default as createyYTyRVrgy2Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/create.vue?macro=true";
import { default as indexMJvGiiHP3KMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/index.vue?macro=true";
import { default as index1DyjFnSpBQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/[uuid]/index.vue?macro=true";
import { default as indexdn1AFINOiIMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/index.vue?macro=true";
import { default as _91uuid_93iTsSJVTgzXMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/[uuid].vue?macro=true";
import { default as createiJOtHNQQ7pMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/create.vue?macro=true";
import { default as index3RBKHK0UnqMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/index.vue?macro=true";
import { default as _91uuid_93EaavWwrHKSMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/[uuid].vue?macro=true";
import { default as createrPRTefWd38Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/create.vue?macro=true";
import { default as index31Vn8brETFMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/index.vue?macro=true";
import { default as socialNKD1FcCTUaMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/social.vue?macro=true";
import { default as indexjJgMlZfb2zMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Demands/index.vue?macro=true";
import { default as _91uuid_93zNVLWcn532Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/[uuid].vue?macro=true";
import { default as index7W5F0U2Y56Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/index.vue?macro=true";
import { default as index1BDNBjLTqqMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/index.vue?macro=true";
import { default as _91uuid_93yuVexNx7UwMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/[uuid].vue?macro=true";
import { default as coda6ae6EKKYrCMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/coda.vue?macro=true";
import { default as createCj7d4y6rd1Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/create.vue?macro=true";
import { default as forecastFkuaorfftKMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/forecast.vue?macro=true";
import { default as index547Z3MiOWYMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/index.vue?macro=true";
import { default as editv4HOExVrQDMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/edit.vue?macro=true";
import { default as indexlLp7vuOM7rMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/index.vue?macro=true";
import { default as createecwIeJm6UnMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/create.vue?macro=true";
import { default as indexhnfAveP6cjMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/index.vue?macro=true";
import { default as _91id_93qKKl9xBthBMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/[id].vue?macro=true";
import { default as createEMRBFj6M4CMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/create.vue?macro=true";
import { default as indexf0FCAhTG7dMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/index.vue?macro=true";
import { default as index5uGk4jbq9mMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Availability/index.vue?macro=true";
import { default as indexZvtIAWJ4xoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Calendar/index.vue?macro=true";
import { default as _91uuid_93ag8BQsnCmoMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/[uuid].vue?macro=true";
import { default as indexliMNItKAxmMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/index.vue?macro=true";
import { default as indexThvLWuvTk3Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/index.vue?macro=true";
import { default as _91uuid_93XKun1GruOxMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/[uuid].vue?macro=true";
import { default as codai3ac0yQPatMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/coda.vue?macro=true";
import { default as createaUQcfguXGCMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/create.vue?macro=true";
import { default as indexrAdl4YsClaMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/index.vue?macro=true";
import { default as _91uuid_93MfGb9PBvWnMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/[uuid].vue?macro=true";
import { default as createl8dsmZQC5UMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/create.vue?macro=true";
import { default as indexRRcETO0prOMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/index.vue?macro=true";
import { default as _91uuid_93hWVRVK65j8Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/[uuid].vue?macro=true";
import { default as indexkybOtTSXxFMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/index.vue?macro=true";
import { default as _91uuid_93IxcFrqUDupMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/[uuid].vue?macro=true";
import { default as create0jRGPwxNK4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/create.vue?macro=true";
import { default as index125B6D60D6Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/index.vue?macro=true";
import { default as indexocqdRRz4pdMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/aes/index.vue?macro=true";
import { default as attendancessX6C0AwiViMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/attendances.vue?macro=true";
import { default as billinghNTzQbKDGkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/billing.vue?macro=true";
import { default as remunerationsNFBMVeXlsPMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/remunerations.vue?macro=true";
import { default as indexyt3R7zD5cnMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/index.vue?macro=true";
import { default as pro_45one9MLgxU8jYUMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/pro-one.vue?macro=true";
import { default as index2I3DqLwTJcMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Settings/index.vue?macro=true";
import { default as indexzN8OQSCuosMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Social/index.vue?macro=true";
import { default as _91uuid_93xZqCpqFgLfMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/[uuid].vue?macro=true";
import { default as create5lJrazdHb1Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/create.vue?macro=true";
import { default as indexLqPnJbdwlOMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/index.vue?macro=true";
import { default as account2ilh5YPWzAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/account.vue?macro=true";
import { default as babiesFxioAcbjCbMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/babies.vue?macro=true";
import { default as indexg9S9TBYk0yMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/index.vue?macro=true";
import { default as invoicesFpyFbwkWhyMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/invoices.vue?macro=true";
import { default as mailsfE9PwquqMSMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/mails.vue?macro=true";
import { default as indexcxZt1QC1kHMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/index.vue?macro=true";
import { default as balancesTMrLWsYyRWMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/balances.vue?macro=true";
import { default as indexhYEGr5gP8LMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/index.vue?macro=true";
import { default as _91uuid_93QAYVhrghBHMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/[uuid].vue?macro=true";
import { default as createqdzkQLifURMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/aes/create.vue?macro=true";
import { default as createWLmr0Ob45lMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/create.vue?macro=true";
import { default as indexFkvp3YtmwpMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/index.vue?macro=true";
import { default as fiscaldocumentscidt4R0ximMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/fiscaldocuments.vue?macro=true";
import { default as oneots9b7FuWxMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/one.vue?macro=true";
import { default as pro_45oneZDRkyICTzKMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/pro-one.vue?macro=true";
import { default as remunerationsQylD4mFe3UMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/remunerations.vue?macro=true";
import { default as creche0r1SyQwCKQMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/creche.vue?macro=true";
import { default as doctorsf23l6QSaEHMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/doctors.vue?macro=true";
import { default as documentsCziVU2vdHKMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/documents.vue?macro=true";
import { default as financeTaNgqKBdn9Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/finance.vue?macro=true";
import { default as mailtemplatesYERNuthdbnMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/mailtemplates.vue?macro=true";
import { default as schedule5GnJMNuLd8Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/schedule.vue?macro=true";
import { default as _91uuid_93YlSa6jrYaHMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/[uuid].vue?macro=true";
import { default as calendarZSBRUxAXHAMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/calendar.vue?macro=true";
import { default as createNPKP2zEzuHMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/create.vue?macro=true";
import { default as indexaIX5cyd0UPMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/index.vue?macro=true";
import { default as attendancesBdG1AYeh5gMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/attendances.vue?macro=true";
import { default as availability1QIBPHxKIBMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/availability.vue?macro=true";
import { default as babiesB8bsH0oqzkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/babies.vue?macro=true";
import { default as financeW0Lk1TzerTMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/finance.vue?macro=true";
import { default as index3TWqtW7xjsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/index.vue?macro=true";
import { default as medicalesm0JKHjpzMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/medical.vue?macro=true";
import { default as registrationsRA7cD6P78sMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/registrations.vue?macro=true";
import { default as indexTcLwzqdYjMMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Activity/index.vue?macro=true";
import { default as indexlutSlxmNl5Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Attendances/index.vue?macro=true";
import { default as _91uuid_936efx60MO22Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/[uuid].vue?macro=true";
import { default as createyp3nu9tAeKMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/create.vue?macro=true";
import { default as indexW0Ldz0WNJ0Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/index.vue?macro=true";
import { default as dayNuTBTYbglmMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/day.vue?macro=true";
import { default as monthjyVOcqgZtjMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/month.vue?macro=true";
import { default as indexAB7ZVuFWAsMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/index.vue?macro=true";
import { default as _91id_93h4oGMrzYF9Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/[id].vue?macro=true";
import { default as createHj4aJazpHcMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/create.vue?macro=true";
import { default as index6vGJm1cVvmMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/index.vue?macro=true";
import { default as indexXTVwBbcpgkMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Scan/index.vue?macro=true";
import { default as indexX55h9wNgeSMeta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Social/index.vue?macro=true";
import { default as indexViyyHgLiN4Meta } from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Staff/index.vue?macro=true";
export default [
  {
    name: "Activity",
    path: "/Activity",
    meta: indexIDkhg8fgYxMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Activity/index.vue")
  },
  {
    name: "Attendances-day",
    path: "/Attendances/day",
    meta: dayodZGYibkMuMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/day.vue")
  },
  {
    name: "Attendances",
    path: "/Attendances",
    meta: index41WbAzsexkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/index.vue")
  },
  {
    name: "Attendances-week",
    path: "/Attendances/week",
    meta: week8RLB4HVz9YMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Attendances/week.vue")
  },
  {
    name: "Auth-login",
    path: "/Auth/login",
    meta: loginfky1OE6c5lMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/login.vue")
  },
  {
    name: "Auth-logout",
    path: "/Auth/logout",
    meta: logoutxtSRgNbZF4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Auth/logout.vue")
  },
  {
    name: "Availability",
    path: "/Availability",
    meta: indexj0H54jjC15Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Availability/index.vue")
  },
  {
    name: "Babies-uuid-activities",
    path: "/Babies/:uuid()/activities",
    meta: activitiesP1xPEaHZsIMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/activities.vue")
  },
  {
    name: "Babies-uuid-calendar",
    path: "/Babies/:uuid()/calendar",
    meta: calendarZkmTVA09KPMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/calendar.vue")
  },
  {
    name: "Babies-uuid-classroom",
    path: "/Babies/:uuid()/classroom",
    meta: classroom40SNHbGiDhMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/classroom.vue")
  },
  {
    name: "Babies-uuid-documents",
    path: "/Babies/:uuid()/documents",
    meta: documentsBDgPDIH4uAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/documents.vue")
  },
  {
    name: "Babies-uuid-finance",
    path: "/Babies/:uuid()/finance",
    meta: financeaBHku2DmAmMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/finance.vue")
  },
  {
    name: "Babies-uuid-history",
    path: "/Babies/:uuid()/history",
    meta: history5sqhFdUxQwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/history.vue")
  },
  {
    name: "Babies-uuid",
    path: "/Babies/:uuid()",
    meta: indexsEdbbm1nt1Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/index.vue")
  },
  {
    name: "Babies-uuid-medical",
    path: "/Babies/:uuid()/medical",
    meta: medicalqCpTEWH5kfMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/medical.vue")
  },
  {
    name: "Babies-uuid-parents",
    path: "/Babies/:uuid()/parents",
    meta: parents5OpXyW4nDcMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/parents.vue")
  },
  {
    name: "Babies-uuid-registration",
    path: "/Babies/:uuid()/registration",
    meta: registrationHHy2OBQpOSMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/[uuid]/registration.vue")
  },
  {
    name: "Babies-archived",
    path: "/Babies/archived",
    meta: archivedME7uO4NxGOMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/archived.vue")
  },
  {
    name: "Babies-create",
    path: "/Babies/create",
    meta: createyYTyRVrgy2Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/create.vue")
  },
  {
    name: "Babies",
    path: "/Babies",
    meta: indexMJvGiiHP3KMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Babies/index.vue")
  },
  {
    name: "Classrooms-uuid",
    path: "/Classrooms/:uuid()",
    meta: index1DyjFnSpBQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/[uuid]/index.vue")
  },
  {
    name: "Classrooms",
    path: "/Classrooms",
    meta: indexdn1AFINOiIMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Classrooms/index.vue")
  },
  {
    name: "Communications-Emails-uuid",
    path: "/Communications/Emails/:uuid()",
    meta: _91uuid_93iTsSJVTgzXMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/[uuid].vue")
  },
  {
    name: "Communications-Emails-create",
    path: "/Communications/Emails/create",
    meta: createiJOtHNQQ7pMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/create.vue")
  },
  {
    name: "Communications-Emails",
    path: "/Communications/Emails",
    meta: index3RBKHK0UnqMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Emails/index.vue")
  },
  {
    name: "Communications-Sms-uuid",
    path: "/Communications/Sms/:uuid()",
    meta: _91uuid_93EaavWwrHKSMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/[uuid].vue")
  },
  {
    name: "Communications-Sms-create",
    path: "/Communications/Sms/create",
    meta: createrPRTefWd38Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/create.vue")
  },
  {
    name: "Communications-Sms",
    path: "/Communications/Sms",
    meta: index31Vn8brETFMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/Sms/index.vue")
  },
  {
    name: "Communications-social",
    path: "/Communications/social",
    meta: socialNKD1FcCTUaMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Communications/social.vue")
  },
  {
    name: "Demands",
    path: "/Demands",
    meta: indexjJgMlZfb2zMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Demands/index.vue")
  },
  {
    name: "Events-uuid",
    path: "/Events/:uuid()",
    meta: _91uuid_93zNVLWcn532Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/[uuid].vue")
  },
  {
    name: "Events",
    path: "/Events",
    meta: index7W5F0U2Y56Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Events/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1BDNBjLTqqMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/index.vue")
  },
  {
    name: "Invoices-uuid",
    path: "/Invoices/:uuid()",
    meta: _91uuid_93yuVexNx7UwMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/[uuid].vue")
  },
  {
    name: "Invoices-coda",
    path: "/Invoices/coda",
    meta: coda6ae6EKKYrCMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/coda.vue")
  },
  {
    name: "Invoices-create",
    path: "/Invoices/create",
    meta: createCj7d4y6rd1Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/create.vue")
  },
  {
    name: "Invoices-forecast",
    path: "/Invoices/forecast",
    meta: forecastFkuaorfftKMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/forecast.vue")
  },
  {
    name: "Invoices",
    path: "/Invoices",
    meta: index547Z3MiOWYMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Invoices/index.vue")
  },
  {
    name: "MedicalVisits-uuid-edit",
    path: "/MedicalVisits/:uuid()/edit",
    meta: editv4HOExVrQDMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/edit.vue")
  },
  {
    name: "MedicalVisits-uuid",
    path: "/MedicalVisits/:uuid()",
    meta: indexlLp7vuOM7rMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/[uuid]/index.vue")
  },
  {
    name: "MedicalVisits-create",
    path: "/MedicalVisits/create",
    meta: createecwIeJm6UnMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/create.vue")
  },
  {
    name: "MedicalVisits",
    path: "/MedicalVisits",
    meta: indexhnfAveP6cjMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/MedicalVisits/index.vue")
  },
  {
    name: "Messages-id",
    path: "/Messages/:id()",
    meta: _91id_93qKKl9xBthBMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/[id].vue")
  },
  {
    name: "Messages-create",
    path: "/Messages/create",
    meta: createEMRBFj6M4CMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/create.vue")
  },
  {
    name: "Messages",
    path: "/Messages",
    meta: indexf0FCAhTG7dMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Messages/index.vue")
  },
  {
    name: "Organization-Availability",
    path: "/Organization/Availability",
    meta: index5uGk4jbq9mMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Availability/index.vue")
  },
  {
    name: "Organization-Calendar",
    path: "/Organization/Calendar",
    meta: indexZvtIAWJ4xoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Calendar/index.vue")
  },
  {
    name: "Organization-Creches-uuid",
    path: "/Organization/Creches/:uuid()",
    meta: _91uuid_93ag8BQsnCmoMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/[uuid].vue")
  },
  {
    name: "Organization-Creches",
    path: "/Organization/Creches",
    meta: indexliMNItKAxmMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Creches/index.vue")
  },
  {
    name: "Organization",
    path: "/Organization",
    meta: indexThvLWuvTk3Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/index.vue")
  },
  {
    name: "Organization-Invoices-uuid",
    path: "/Organization/Invoices/:uuid()",
    meta: _91uuid_93XKun1GruOxMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/[uuid].vue")
  },
  {
    name: "Organization-Invoices-coda",
    path: "/Organization/Invoices/coda",
    meta: codai3ac0yQPatMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/coda.vue")
  },
  {
    name: "Organization-Invoices-create",
    path: "/Organization/Invoices/create",
    meta: createaUQcfguXGCMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/create.vue")
  },
  {
    name: "Organization-Invoices",
    path: "/Organization/Invoices",
    meta: indexrAdl4YsClaMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Invoices/index.vue")
  },
  {
    name: "Organization-Messages-uuid",
    path: "/Organization/Messages/:uuid()",
    meta: _91uuid_93MfGb9PBvWnMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/[uuid].vue")
  },
  {
    name: "Organization-Messages-create",
    path: "/Organization/Messages/create",
    meta: createl8dsmZQC5UMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/create.vue")
  },
  {
    name: "Organization-Messages",
    path: "/Organization/Messages",
    meta: indexRRcETO0prOMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Messages/index.vue")
  },
  {
    name: "Organization-Parents-uuid",
    path: "/Organization/Parents/:uuid()",
    meta: _91uuid_93hWVRVK65j8Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/[uuid].vue")
  },
  {
    name: "Organization-Parents",
    path: "/Organization/Parents",
    meta: indexkybOtTSXxFMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Parents/index.vue")
  },
  {
    name: "Organization-Registrations-uuid",
    path: "/Organization/Registrations/:uuid()",
    meta: _91uuid_93IxcFrqUDupMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/[uuid].vue")
  },
  {
    name: "Organization-Registrations-create",
    path: "/Organization/Registrations/create",
    meta: create0jRGPwxNK4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/create.vue")
  },
  {
    name: "Organization-Registrations",
    path: "/Organization/Registrations",
    meta: index125B6D60D6Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Registrations/index.vue")
  },
  {
    name: "Organization-Reports-aes",
    path: "/Organization/Reports/aes",
    meta: indexocqdRRz4pdMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/aes/index.vue")
  },
  {
    name: "Organization-Reports-attendances",
    path: "/Organization/Reports/attendances",
    meta: attendancessX6C0AwiViMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/attendances.vue")
  },
  {
    name: "Organization-Reports-billing",
    path: "/Organization/Reports/billing",
    meta: billinghNTzQbKDGkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/billing.vue")
  },
  {
    name: "Organization-Reports-remunerations",
    path: "/Organization/Reports/remunerations",
    meta: remunerationsNFBMVeXlsPMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/remunerations.vue")
  },
  {
    name: "Organization-Reports-sae",
    path: "/Organization/Reports/sae",
    meta: indexyt3R7zD5cnMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/index.vue")
  },
  {
    name: "Organization-Reports-sae-pro-one",
    path: "/Organization/Reports/sae/pro-one",
    meta: pro_45one9MLgxU8jYUMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Reports/sae/pro-one.vue")
  },
  {
    name: "Organization-Settings",
    path: "/Organization/Settings",
    meta: index2I3DqLwTJcMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Settings/index.vue")
  },
  {
    name: "Organization-Social",
    path: "/Organization/Social",
    meta: indexzN8OQSCuosMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Social/index.vue")
  },
  {
    name: "Organization-Staff-uuid",
    path: "/Organization/Staff/:uuid()",
    meta: _91uuid_93xZqCpqFgLfMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/[uuid].vue")
  },
  {
    name: "Organization-Staff-create",
    path: "/Organization/Staff/create",
    meta: create5lJrazdHb1Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/create.vue")
  },
  {
    name: "Organization-Staff",
    path: "/Organization/Staff",
    meta: indexLqPnJbdwlOMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Organization/Staff/index.vue")
  },
  {
    name: "Parents-uuid-account",
    path: "/Parents/:uuid()/account",
    meta: account2ilh5YPWzAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/account.vue")
  },
  {
    name: "Parents-uuid-babies",
    path: "/Parents/:uuid()/babies",
    meta: babiesFxioAcbjCbMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/babies.vue")
  },
  {
    name: "Parents-uuid",
    path: "/Parents/:uuid()",
    meta: indexg9S9TBYk0yMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/index.vue")
  },
  {
    name: "Parents-uuid-invoices",
    path: "/Parents/:uuid()/invoices",
    meta: invoicesFpyFbwkWhyMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/invoices.vue")
  },
  {
    name: "Parents-uuid-mails",
    path: "/Parents/:uuid()/mails",
    meta: mailsfE9PwquqMSMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/[uuid]/mails.vue")
  },
  {
    name: "Parents",
    path: "/Parents",
    meta: indexcxZt1QC1kHMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Parents/index.vue")
  },
  {
    name: "Payments-balances",
    path: "/Payments/balances",
    meta: balancesTMrLWsYyRWMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/balances.vue")
  },
  {
    name: "Payments",
    path: "/Payments",
    meta: indexhYEGr5gP8LMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Payments/index.vue")
  },
  {
    name: "Registrations-uuid",
    path: "/Registrations/:uuid()",
    meta: _91uuid_93QAYVhrghBHMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/[uuid].vue")
  },
  {
    name: "Registrations-aes-create",
    path: "/Registrations/aes/create",
    meta: createqdzkQLifURMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/aes/create.vue")
  },
  {
    name: "Registrations-create",
    path: "/Registrations/create",
    meta: createWLmr0Ob45lMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/create.vue")
  },
  {
    name: "Registrations",
    path: "/Registrations",
    meta: indexFkvp3YtmwpMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Registrations/index.vue")
  },
  {
    name: "Reporting-fiscaldocuments",
    path: "/Reporting/fiscaldocuments",
    meta: fiscaldocumentscidt4R0ximMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/fiscaldocuments.vue")
  },
  {
    name: "Reporting-one",
    path: "/Reporting/one",
    meta: oneots9b7FuWxMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/one.vue")
  },
  {
    name: "Reporting-pro-one",
    path: "/Reporting/pro-one",
    meta: pro_45oneZDRkyICTzKMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/pro-one.vue")
  },
  {
    name: "Reporting-remunerations",
    path: "/Reporting/remunerations",
    meta: remunerationsQylD4mFe3UMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Reporting/remunerations.vue")
  },
  {
    name: "Settings-creche",
    path: "/Settings/creche",
    meta: creche0r1SyQwCKQMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/creche.vue")
  },
  {
    name: "Settings-doctors",
    path: "/Settings/doctors",
    meta: doctorsf23l6QSaEHMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/doctors.vue")
  },
  {
    name: "Settings-documents",
    path: "/Settings/documents",
    meta: documentsCziVU2vdHKMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/documents.vue")
  },
  {
    name: "Settings-finance",
    path: "/Settings/finance",
    meta: financeTaNgqKBdn9Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/finance.vue")
  },
  {
    name: "Settings-mailtemplates",
    path: "/Settings/mailtemplates",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/mailtemplates.vue")
  },
  {
    name: "Settings-schedule",
    path: "/Settings/schedule",
    meta: schedule5GnJMNuLd8Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Settings/schedule.vue")
  },
  {
    name: "Staff-uuid",
    path: "/Staff/:uuid()",
    meta: _91uuid_93YlSa6jrYaHMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/[uuid].vue")
  },
  {
    name: "Staff-calendar",
    path: "/Staff/calendar",
    meta: calendarZSBRUxAXHAMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/calendar.vue")
  },
  {
    name: "Staff-create",
    path: "/Staff/create",
    meta: createNPKP2zEzuHMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/create.vue")
  },
  {
    name: "Staff",
    path: "/Staff",
    meta: indexaIX5cyd0UPMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Staff/index.vue")
  },
  {
    name: "Stats-attendances",
    path: "/Stats/attendances",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/attendances.vue")
  },
  {
    name: "Stats-availability",
    path: "/Stats/availability",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/availability.vue")
  },
  {
    name: "Stats-babies",
    path: "/Stats/babies",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/babies.vue")
  },
  {
    name: "Stats-finance",
    path: "/Stats/finance",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/finance.vue")
  },
  {
    name: "Stats",
    path: "/Stats",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/index.vue")
  },
  {
    name: "Stats-medical",
    path: "/Stats/medical",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/medical.vue")
  },
  {
    name: "Stats-registrations",
    path: "/Stats/registrations",
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Stats/registrations.vue")
  },
  {
    name: "Tablet-Activity",
    path: "/Tablet/Activity",
    meta: indexTcLwzqdYjMMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Activity/index.vue")
  },
  {
    name: "Tablet-Attendances",
    path: "/Tablet/Attendances",
    meta: indexlutSlxmNl5Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Attendances/index.vue")
  },
  {
    name: "Tablet-Babies-uuid",
    path: "/Tablet/Babies/:uuid()",
    meta: _91uuid_936efx60MO22Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/[uuid].vue")
  },
  {
    name: "Tablet-Babies-create",
    path: "/Tablet/Babies/create",
    meta: createyp3nu9tAeKMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/create.vue")
  },
  {
    name: "Tablet-Babies",
    path: "/Tablet/Babies",
    meta: indexW0Ldz0WNJ0Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Babies/index.vue")
  },
  {
    name: "Tablet-Calendar-day",
    path: "/Tablet/Calendar/day",
    meta: dayNuTBTYbglmMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/day.vue")
  },
  {
    name: "Tablet-Calendar-month",
    path: "/Tablet/Calendar/month",
    meta: monthjyVOcqgZtjMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Calendar/month.vue")
  },
  {
    name: "Tablet",
    path: "/Tablet",
    meta: indexAB7ZVuFWAsMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/index.vue")
  },
  {
    name: "Tablet-Messages-id",
    path: "/Tablet/Messages/:id()",
    meta: _91id_93h4oGMrzYF9Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/[id].vue")
  },
  {
    name: "Tablet-Messages-create",
    path: "/Tablet/Messages/create",
    meta: createHj4aJazpHcMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/create.vue")
  },
  {
    name: "Tablet-Messages",
    path: "/Tablet/Messages",
    meta: index6vGJm1cVvmMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Messages/index.vue")
  },
  {
    name: "Tablet-Scan",
    path: "/Tablet/Scan",
    meta: indexXTVwBbcpgkMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Scan/index.vue")
  },
  {
    name: "Tablet-Social",
    path: "/Tablet/Social",
    meta: indexX55h9wNgeSMeta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Social/index.vue")
  },
  {
    name: "Tablet-Staff",
    path: "/Tablet/Staff",
    meta: indexViyyHgLiN4Meta || {},
    component: () => import("/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/pages/Tablet/Staff/index.vue")
  }
]